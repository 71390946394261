import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Layout from '../components/layout/layout'

export default function NotFoundPage() {
  const style = {
    fontFamily: "Muli",
  }
  return (
    <Layout>
      <SEO
        title="No encontrado"
        description="¡Página no encontrada!"
        url="/404"
      />
      <div
        style={{
          height: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          margin: "1.3em",
        }}
      >
        <h2 style={style}>
          Esta página no existe o el contenido ha sido removido
        </h2>
        <br />
        <p style={style}>
          Para regresar al home puedes dar clic{" "}
          <Link to="/" title="home">
            Aquí
          </Link>
        </p>
      </div>
    </Layout>
  )
}
